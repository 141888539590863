// ./components/Accordion.tsx
import { useState, useEffect } from "preact/hooks";

interface AccordionProps {
  title: string;
  children: preact.ComponentChildren;
  allOpen: boolean;  // Prop für "Alles aufklappen"
}

export default function Accordion({ title, children, allOpen }: AccordionProps) {
  const [isOpen, setIsOpen] = useState(false);

  // Effekt, um den Zustand basierend auf allOpen zu aktualisieren, nur wenn allOpen geändert wird
  useEffect(() => {
    setIsOpen(allOpen);
  }, [allOpen]);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div class="accordion">
      <div
        class="accordion-header flex justify-between items-center p-4 bg-slate-300 rounded-lg shadow-md space-x-1 my-1"
        onClick={toggleAccordion}
      >
        <h3 class="text-lg font-semibold">{title}</h3>
        <button class="text-blue-500">
          {isOpen ? "▲" : "▼"}
        </button>
      </div>

      {/* Der Inhalt bleibt immer im DOM, aber seine Sichtbarkeit wird durch CSS gesteuert */}
      <div class={`accordion-body mt-4 ${isOpen ? 'block' : 'hidden'}`}>
        {children}
      </div>
    </div>
  );
}
